//@import "bootstrap/bootstrap";

body {
  font-family: myriad-pro, roboto, Geneva,Tahoma,sans-serif !important;
  margin: 0;
}

.h-100 {
  height: 100%;
}

.dip-profile-container {   
  display: flex;
  flex-direction: column;
  background-color: transparent;
  height: 100%;
}

.flexgrow {
  display: flex;
  flex: 1 0 auto;
}